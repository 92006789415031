import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { graphql } from "gatsby";
import Layout from "../layout/index";
import LayoutEN from "../layout/index.en";
import DownloadAPP from "../components/DownloadAPP/DownloadAPP";
import DownloadAPPEN from "../components/DownloadAPP/DownloadAPP.en";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./post.css";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    const langKey = postNode.frontmatter.langKey;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <div>
        { langKey == 'en' && 
        <LayoutEN>
          <HelmetProvider>
            <Helmet>
              <title>{`${post.title} | ArgoVPN`}</title>
            </Helmet>
          </HelmetProvider>        
          <div className="postContainer en">
            <SEO postPath={slug} postNode={postNode} postSEO />
            <div className="post">
              <h1 className="headline">{post.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            </div>
          </div>
          <DownloadAPPEN />
        </LayoutEN>
        }
        { langKey != 'en' && 
        <Layout>
          <HelmetProvider>
            <Helmet>
              <title>{`${post.title} | ${config.siteTitle}`}</title>
            </Helmet>
          </HelmetProvider>        
          <div className="postContainer">
            <SEO postPath={slug} postNode={postNode} postSEO />
            <div className="post">
              <h1 className="headline">{post.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            </div>
          </div>
          <DownloadAPP />
        </Layout>
        }        
      </div>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      ) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
        langKey
      }
      fields {
        slug
        date
      }
    }
  }
`;
