import React, { Component } from "react";
import { Link } from "gatsby";
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <header className="header">
        <div className="headerContainer">
          <div className="menubar">
            <ul>
              <li><Link to="https://argovpn.com">Home</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/about">About</Link></li>
            </ul>
          </div>
					<Link to="/">فا</Link>
          {/* <svg width="379px" height="379px" viewBox="0 0 379 379">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-137.000000, -398.000000)">
                      <g transform="translate(137.000000, 398.000000)">
                          <g>
                              <path d="M285.15525,137.290831 C279.707109,126.205225 272.554904,115.157039 263.774107,104.687302 C259.075378,99.0847991 254.132631,93.9130196 249.025458,89.1977533 C268.517118,96.6693914 284.760555,103.045013 285.405295,103.634794 C285.897126,104.0847 286.561014,117.38314 285.155251,137.290814 Z M197.501793,69.7774986 C215.636008,79.6779758 233.575742,97.4491199 247.028512,120.75 C264.865366,151.644338 270.33947,184.509293 263.728049,207.951843 C256.568918,186.97088 244.447544,165.341784 227.683398,145.363053 C197.973952,109.956714 160.627895,88.0103243 127.935418,83.4965721 C153.748264,73.6924163 179.849514,64 181.008808,64 C181.57273,64 188.038392,66.2934099 197.501786,69.777496 Z M173.562372,318.771274 C99.5050052,289.73736 80.888149,192.32271 76.9424383,139.090382 C103.817911,150.238555 131.36449,175.286303 151.248695,209.726755 C173.354709,248.015496 180.671833,288.70133 173.562372,318.771274 Z M76.2060277,125.931362 C75.707518,112.469378 76.2213478,103.992441 76.6123216,103.634794 C77.2169276,103.081725 91.5386927,97.4407137 109.392937,90.5791687 C142.43671,93.623837 180.816336,122.203188 206.387652,166.494005 C234.891068,215.86337 238.531707,269.254115 218.091962,298.101936 C219.05139,264.749618 203.682319,223.894406 173.749784,188.214171 C144.70559,153.592861 108.483093,131.632056 76.2060277,125.931362 Z" fill="#75FFC0" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </svg> */}

        </div>
      </header>
    );
  }
}

export default Header;
